import React from 'react';
import PropTypes from 'prop-types';

import { getYoutubeData, getMenus } from 'libs/api';
import VARIAVEIS_GLOBAIS from 'theme/utils/globalVariables';
import getHighlightNews, { getMatchFacts } from 'libs/api/Home';

import {
  isEmpty,
  verifyUserAgent,
  extractContentOfTagTitle,
} from 'theme/utils/functions';

import Home from 'components/pages/Home';
import Box from 'components/foundation/layout/Box';
import BetPageHOC from 'components/wrapper/BetPage/hoc';

const HomePage = ({
  agent,
  isMobile,
  matchFacts,
  highlightNews,
  youtubeShorts,
  eventsIdsAndFactsUris,
} = {}) => (
  <Box
    as='main'
    $cssWidth='100%'
    data-agent={agent}
  >
    <Home
      isMobile={isMobile}
      matchFacts={matchFacts}
      youtubeShorts={youtubeShorts}
      highlightNews={highlightNews}
      eventsIdsAndFactsUris={eventsIdsAndFactsUris}
    />
  </Box>
);

HomePage.propTypes = {
  // Returns the user's User-Agent
  agent: PropTypes.string,
  // Returns whether the request is from a mobile device
  isMobile: PropTypes.bool,
  // Returns the match facts
  matchFacts: PropTypes.shape({}).isRequired,
  // Array with the featured news
  highlightNews: PropTypes.shape({}).isRequired,
  // Array with the events ids and facts uris
  eventsIdsAndFactsUris: PropTypes.arrayOf(PropTypes.shape({})),
  // Array with the shorts from youtube
  youtubeShorts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  // Returns the games with on Home Betnacional
  matchsHome: PropTypes.shape({
    oddsWord: PropTypes.shape({}),
    oddsBrazil: PropTypes.shape({}),
    odds: PropTypes.shape({}).isRequired,
    live_count: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export async function getServerSideProps({ req, res }) {
  // Setting the cache on the server side
  res.setHeader(
    'Cache-Control',
    `public, max-age=${VARIAVEIS_GLOBAIS.CACHE.HOME}`
  );
  const agent = req.headers['user-agent'];
  const isMobile = verifyUserAgent({ userAgent: agent });

  const customHeaders = {
    'User-Agent': `${agent} Nextjs/SSR`,
  };

  const dataMenus = await getMenus({ ssr: true, customHeaders });

  const highlightNews = await getHighlightNews({ ssr: true, customHeaders });

  // const matchFacts = await getMatchFacts({ ssr: true, customHeaders });
  let matchFacts = { noticias: [] };
  let eventsIdsAndFactsUris = [];

  try {
    matchFacts = await getMatchFacts({
      ssr: true,
      customHeaders,
    });
  } catch (error) {
    console.error('Failed to fetch match facts:', error);
  }

  if (!isEmpty(matchFacts.noticias)) {
    const index = highlightNews.moreNews.findIndex(
      cat => cat.categoria.nome === 'Fatos do Confronto'
    );

    if (index !== -1) {
      highlightNews.moreNews[index].categoria.news = matchFacts.noticias;
    }

    eventsIdsAndFactsUris = matchFacts.noticias.map(noticia => ({
      uri: noticia.uri,
      scoresEventId: noticia.scoresEventId,
    }));
  }

  const dataYoutubeShorts = await getYoutubeData({
    ssr: true,
    customHeaders,
    params: 'shorts',
  });

  return {
    props: {
      agent,
      isMobile,
      matchFacts,
      eventsIdsAndFactsUris,
      highlightNews: highlightNews || {},
      youtubeShorts: dataYoutubeShorts?.videos || [],
      pageWrapperProps: {
        customLogoTag: 'h1',
        seoProps: {
          description: null,
          seo: highlightNews?.seo || '',
          title: extractContentOfTagTitle({ src: highlightNews?.seo?.head }),
        },
        menuProps: {
          display: true,
          menus: dataMenus?.menu || {},
        },
      },
    },
  };
}

export default BetPageHOC(React.memo(HomePage), {
  pageWrapperProps: {
    seoProps: {},
    pageBoxProps: {
      className: 'home',
    },
  },
});
