import React from 'react';
import Skeleton from 'react-loading-skeleton';

import spacings from 'theme/spacings';
import Text from 'components/foundation/Text';
import Grid from 'components/foundation/layout/Grid';

import * as S from './styles';

const LiveOddsSkeleton = () => (
  <S.LiveOddsWrapper>
    <div className='live-odds__slider-wrapper live-odds__skeleton'>
      <Text
        as='h4'
        $csscolor='600'
        $variant={{ typography: 'heading4' }}
      >
        Probabilidades em alta
      </Text>
      <Grid.Row $cssMargin={`${spacings.md} 0`}>
        <Grid.Col
          $cssDisplay='flex'
          $colValue={{ xs: 4 }}
        >
          <Skeleton
            width='100%'
            height={60}
          />
        </Grid.Col>
        <Grid.Col
          $cssDisplay='flex'
          $colValue={{ xs: 4 }}
        >
          <Skeleton
            width='100%'
            height={60}
          />
        </Grid.Col>
        <Grid.Col
          $cssDisplay='flex'
          $colValue={{ xs: 4 }}
        >
          <Skeleton
            width='100%'
            height={60}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col
          $cssDisplay='flex'
          $colValue={{ xs: 12 }}
          $cssPadding={`0 ${spacings.md}`}
        >
          <Skeleton
            width='100%'
            height={82}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col
          $cssDisplay='flex'
          $colValue={{ xs: 12 }}
          $cssPadding={`${spacings.sm} ${spacings.md}`}
        >
          <Skeleton
            width='100%'
            height={40}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col
          $cssDisplay='flex'
          $colValue={{ xs: 12 }}
          $cssPadding={`0 ${spacings.md}`}
        >
          <Skeleton
            width='100%'
            height={18}
          />
        </Grid.Col>
      </Grid.Row>
      <Grid.Row>
        <Grid.Col
          $cssDisplay='flex'
          $colValue={{ xs: 12 }}
          $cssPadding={`${spacings.sm} ${spacings.md} 0`}
        >
          <Skeleton
            width='100%'
            height={18}
          />
        </Grid.Col>
      </Grid.Row>
    </div>
  </S.LiveOddsWrapper>
);

export default React.memo(LiveOddsSkeleton);
