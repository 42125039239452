// components/data/FeaturedEvents/LazyLoadFeaturedEvents.js

import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import useIntersectionObserver from 'hooks/useIntersectionObserver';
import FeaturedEventsSkeleton from './FeaturedEventsSkeleton';

import * as S from './styles';

const FeaturedEvents = dynamic(() => import('./FeaturedEvents'), {
  ssr: false,
  loading: () => <FeaturedEventsSkeleton />,
});

const LazyLoadFeaturedEvents = ({
  isMobile,
  className = '',
  eventsIdsAndFactsUris = [],
}) => {
  const [shouldRender, setShouldRender] = useState(false);
  const { containerRef } = useIntersectionObserver(() => {
    setShouldRender(true);
  });

  return (
    <>
      {!shouldRender && <FeaturedEventsSkeleton />}

      <S.FeaturedEventsWrapper
        ref={containerRef}
        className={`${className} ${shouldRender ? 'visible' : 'hidden'}`}
      >
        {shouldRender && (
          <FeaturedEvents
            isMobile={isMobile}
            eventsIdsAndFactsUris={eventsIdsAndFactsUris}
          />
        )}
      </S.FeaturedEventsWrapper>
    </>
  );
};

LazyLoadFeaturedEvents.propTypes = {
  className: PropTypes.string,
  isMobile: PropTypes.bool.isRequired,
  eventsIdsAndFactsUris: PropTypes.arrayOf(PropTypes.shape({})),
};

export default LazyLoadFeaturedEvents;
