import React, { useState } from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import { getBetLives } from 'libs/api/Match';
import useIntersectionObserver from 'hooks/useIntersectionObserver';
import LiveOddsSkeleton from './LiveOddsSkeleton';

const LiveOdds = dynamic(() => import('components/data/LiveOdds'), {
  ssr: false,
  loading: () => <LiveOddsSkeleton />,
});

const LazyLoadLiveOdds = ({ isMobile, className = '' }) => {
  const [liveOddsData, setLiveOddsData] = useState(null);
  const [shouldRenderLiveOdds, setShouldRenderLiveOdds] = useState(false);

  const { containerRef } = useIntersectionObserver(() => {
    setShouldRenderLiveOdds(true);
  });

  return (
    <>
      {!shouldRenderLiveOdds && <LiveOddsSkeleton />}

      <div
        ref={containerRef}
        className={`${className} ${shouldRenderLiveOdds ? 'visible' : 'hidden'}`}
      >
        {shouldRenderLiveOdds && (
          <LiveOdds
            isMobile={isMobile}
            data={liveOddsData}
            className={className}
            fetchDataOnClient={async () => {
              const data = await getBetLives();
              setLiveOddsData(data);
              return data;
            }}
          />
        )}
      </div>
    </>
  );
};

LazyLoadLiveOdds.propTypes = {
  isMobile: PropTypes.bool.isRequired,
  className: PropTypes.string,
};

export default LazyLoadLiveOdds;
