import React from 'react';
import dynamic from 'next/dynamic';
import PropTypes from 'prop-types';

import { isEmpty } from 'theme/utils/functions';

import spacings from 'theme/spacings';
import Grid from 'components/foundation/layout/Grid';
import StaticBanner from 'components/common/StaticBanner';
import ArticleHighlight from 'components/common/HomeArticleHighlight';
import LazyLoadFeaturedEvents from 'components/data/FeaturedEvents/LazyLoadFeaturedEvents';
import DynamicCategoriesList from './DynamicCategoriesList';

const LazyLoadFeaturedVideos = dynamic(
  () => import('components/common/FeaturedVideos/LazyLoadFeaturedVideos'),
  { ssr: false }
);

const LazyLoadArticlesByCategory = dynamic(
  () =>
    import('components/common/ArticlesByCategory/LazyLoadArticlesByCategory'),
  { ssr: false }
);

const Home = ({
  isMobile,
  highlightNews,
  youtubeShorts,
  eventsIdsAndFactsUris = [],
}) => {
  const { hotNews = [], featuredPosts = [], moreNews = [] } = highlightNews;
  const categoriesPagination = {
    subCategorys: [...(hotNews || [])],
    Category: {
      categoryFather: null,
      subCategorys: [...(hotNews || [])],
    },
  };

  const excludePosts = [
    ...featuredPosts.map(n => n.noticia.id),
    ...moreNews.flatMap(
      n => n?.categoria?.news?.map(ne => ne.noticia?.id ?? ne?.id) || []
    ),
  ];

  return (
    <Grid.Container>
      <Grid.Row $cssPadding={{ xs: `${spacings.md} 0 0` }}>
        <Grid.Col $colValue={{ xs: 12 }}>
          <StaticBanner
            bannerFormat='horizontal'
            title='Betnacional - A Bet do Brasil'
            mobileImage='https://media.nsxafiliados.com/uploads/425-x-90.gif'
            desktopImage='https://media.nsxafiliados.com/uploads/970x90px_1.gif'
            link='https://record.nsxafiliados.com/_bG5FcVwwsutEBTMAXJzE24Ko5xzWaAoJ/1/'
          />
        </Grid.Col>
      </Grid.Row>

      <LazyLoadFeaturedEvents
        isMobile={isMobile}
        className='highlight__featuredEvents'
        eventsIdsAndFactsUris={eventsIdsAndFactsUris}
      />

      <ArticleHighlight
        isMobile={isMobile}
        youtubeShorts={youtubeShorts}
        highlightNews={highlightNews?.featuredPosts}
      />

      <DynamicCategoriesList
        isMobile={isMobile}
        data={highlightNews?.moreNews}
      />

      <LazyLoadFeaturedVideos />

      {isEmpty(hotNews) ? null : (
        <Grid.Row>
          <Grid.Col $colValue={{ xs: 12, lg: 9 }}>
            <LazyLoadArticlesByCategory
              isHotNews
              showLoadLoreButton
              isMobile={isMobile}
              excludePosts={excludePosts}
              data={categoriesPagination}
              articleProps={{
                showDate: true,
                horizontal: true,
                showExcerpt: true,
                thumbnailSize: 'small',
                titleTypography: 'heading1',
              }}
            />
          </Grid.Col>
          <Grid.Col
            $colValue={{ xs: 12, lg: 3 }}
            $cssMargin={{
              xs: `${spacings.xl} 0 0`,
              lg: `${spacings.xxxl} 0 0`,
            }}
          >
            <StaticBanner
              sticky
              bannerFormat='vertical'
              title='Betnacional - A Bet do Brasil'
              mobileImage='https://media.nsxafiliados.com/uploads/001x300x250px_1.gif'
              desktopImage='https://media.nsxafiliados.com/uploads/002x300x600px_1.gif'
              link='https://record.nsxafiliados.com/_bG5FcVwwsutEBTMAXJzE24Ko5xzWaAoJ/1/'
            />
          </Grid.Col>
        </Grid.Row>
      )}
    </Grid.Container>
  );
};

Home.propTypes = {
  isMobile: PropTypes.bool,
  eventsIdsAndFactsUris: PropTypes.arrayOf(PropTypes.shape({})),
  youtubeShorts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  highlightNews: PropTypes.shape({
    hotNews: PropTypes.arrayOf(PropTypes.shape({})),
    moreNews: PropTypes.arrayOf(PropTypes.shape({})),
    featuredPosts: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  matchsHome: PropTypes.shape({
    events: PropTypes.shape({}),
    oddsWord: PropTypes.shape({}),
    oddsBrazil: PropTypes.shape({}),
    odds: PropTypes.shape({}).isRequired,
    live_count: PropTypes.arrayOf(PropTypes.shape({})),
  }),
};

export default React.memo(Home);
