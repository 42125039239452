import React from 'react';
import Skeleton from 'react-loading-skeleton';

import Text from 'components/foundation/Text';

import * as S from './styles';

const FeaturedEventsSkeleton = () => (
  <S.FeaturedEventsWrapper className='featured-events__skeleton'>
    <S.Header>
      <Text
        as='h4'
        $csscolor='600'
        $variant={{
          typography: 'heading4',
        }}
      >
        Eventos em destaque
      </Text>
    </S.Header>
    <div className='featured-events__skeleton-box'>
      {Array.from({ length: 6 }).map((_, index) => (
        <Skeleton
          key={index}
          width={230}
          height={88}
        />
      ))}
    </div>
  </S.FeaturedEventsWrapper>
);

export default React.memo(FeaturedEventsSkeleton);
